import React, { useState, useEffect } from 'react';
import axios from 'axios';
import "../components/ConnectedUsers.css";
import { Container, Typography } from '@mui/material';
const ConnectedUsers = () => {
  const [connectedUsers, setConnectedUsers] = useState(0);
  const [serverStatus, setServerStatus] = useState('offline');

  useEffect(() => {
    const fetchConnectedUsers = async () => {
      try {
        const response = await axios.get('https://traphousewall-69bb9a961171.herokuapp.com/api/connected-users');
        setConnectedUsers(response.data.connectedUsers);
      } catch (error) {
        console.error('Errore nel recuperare il numero di utenti connessi:', error);
      }
    };

    fetchConnectedUsers();

    const fetchServerStatus = async () => {
      try {
        const response = await axios.get('https://traphousewall-69bb9a961171.herokuapp.com/api/server-status');
        setServerStatus(response.data.status);
      } catch (error) {
        console.error('Errore nel verificare lo stato del server:', error);
        setServerStatus('offline');
      }
    };

    fetchConnectedUsers();
    fetchServerStatus();
    const interval = setInterval(() => {
      fetchConnectedUsers();
      fetchServerStatus();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
<>
      <Typography style={{  fontSize: "14px", color: "white"}}>
      Users: {connectedUsers}
        </Typography> 
      <Typography style={{fontSize: "14px", color: "white"}} className={`server-status ${serverStatus}`}>
        Status: 
        <span style={{marginLeft: "10px",  fontSize:"14px"}} className="status-icon"></span>
      </Typography>
      </>
  );
};

export default ConnectedUsers;