import { Typography, Container} from "@mui/material";
import React from "react";
const Copyright = () => {


  return (
    <Typography color="white" style={{display: 'flex', fontSize: '12px', alignItems: "center", justifyContent: 'center'}}>
      © 2023 - TRAP HOU$E | dev.
      <a href='https://twitter.com/Andrea__Zero/' target="_blank" rel="noopener noreferrer"  
      style={{textDecoration: 'none', color: '#c100c4', marginLeft: "10px", marginRight: "10px" }}>
        Andrea__Zero
      </a>
      </Typography>

  );
};

export default Copyright;
