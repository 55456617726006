import React, { useEffect, useState } from "react";
import { Card, Stack, Typography, IconButton, Modal, Box, Grid, Container } from "@mui/material";
import { MdRefresh } from "react-icons/md";
import Loading from "./Loading";
import HorizontalStack from "./util/HorizontalStack";
import axios from 'axios';
import { motion } from "framer-motion";
import opensea from "../assets/img/opensea.png";
import blur from "../assets/img/blur.png";
import "../components/ScrollBar.css";
import xlogo from "../assets/img/xlogo.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDice, faFire, faFireBurner, faHandshake, faUser} from "@fortawesome/free-solid-svg-icons";

const FeaturedProject = () => {
  const [loading, setLoading] = useState(true);
  const [nfts, setNfts] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchNFTs = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://traphousewall-69bb9a961171.herokuapp.com/api/random-listings');
      setNfts(response.data);
      console.log(response.data); // Per vedere i dati ricevuti
    } catch (error) {
      console.error('Errore nel recupero delle listings:', error);
      setNfts(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNFTs();
  }, []);

  const convertWeiToEth = (weiValue) => {
    return weiValue / 1e18;
  };
  
  const handleOpenModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    outline: 'none'
  };

  return (
    <Card style={{padding: "15px", 
    alignItems: "center",width: "400px",
    borderRadius: "0.5rem", border: "1px ridge grey", background: 'black' }}>
      <HorizontalStack style={{ backgroundColor: "black", padding: "8px", borderRadius: "0.4rem", display: 'flex', justifyContent: 'space-between' }}>
    
        <span style={{ color: 'white', fontSize: "12px"}}>HOT NFT COLLECTION
        <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faFire}></FontAwesomeIcon>
        </span>
        <Typography style={{fontSize: '12px', display: "flex", alignItems: "center"}}>
        <MdRefresh style={{marginRight: "10px"}} onClick={fetchNFTs} />
          Refresh        
        </Typography>

      </HorizontalStack>
      <Container style={{display: 'flex', alignItems: 'center',  flexDirection: "column", marginTop: "0.4rem", marginBottom: "0.2rem"}}>
      <a style={{textDecoration: 'none'}} href='https://twitter.com/AngryApesNFT' rel="noreferrer noopener" target="blank">
      <Typography style={{ textAlign: "center", fontSize: "14px",  display: 'flex', alignItems: "center", color: "white"}}>
      The Best KNIVES
      </Typography>
      </a>
      </Container>

      {loading ? (
        <Loading />
      ) : (
<Grid container spacing={3} className="custom-scrollbar" style={{maxHeight: '350px', overflowY: 'auto' }}>
  {nfts && nfts.map((nft, index) => (
            <Grid
            item xs={6} key={nft.order_hash || index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Box style={{
                  gap: "0.4rem",
                  padding: "5px",boxShadow: "0px 10px 10px 0px black", 
                  borderRadius: "0.5px",
                  display: 'flex', flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
               <img 
                src={nft.nftDetails.image_url} 
                alt={nft.nftDetails.name} 
                onClick={() => handleOpenModal(nft.nftDetails.image_url)}
              style={{width: '100%', height: '110px', objectFit: "contain" }} />
              <Container 
              style={{ display: "flex",  borderBottom: "3px solid #c100c4",
              borderBottomLeftRadius: "0.5rem", borderBottomRightRadius: "0.5rem",
              flexDirection: 'column', width: "auto", backgroundColor: 'black', padding: "10px" }}>
                <div style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
                  <Typography style={{ fontSize: "12px", color: "white", textAlign: "center" }}>Dork</Typography>
                  <div style={{ display: "flex", gap: "0.4rem", justifyContent: 'space-between', alignItems: "center" }}>
                  <a href={`https://opensea.io/assets/ethereum/0x87696beb1a22e6c23b0be9d2cbc7b0ec0df7e1cd/${nft.nftDetails.token_id}`}
                target="blank" rel="noreferrer noopener">
                    <img src={opensea} alt="opensea" style={{ width: '15px', objectFit: "contain" }}/>
                    </a>
                    <a href={`https://blur.io/asset/0x87696beb1a22e6c23b0be9d2cbc7b0ec0df7e1cd/${nft.nftDetails.token_id}`} target="_blank" rel="noreferrer noopener">
                      <img src={blur} alt="blur" style={{ borderRadius: "1rem", border: "1px solid white", width: '15px', objectFit: "contain" }} />
                  </a>
                    {/* Link to the offerer's OpenSea profile */}
                    <a href={`https://opensea.io/${nft.protocol_data.parameters.offerer}`}
                      target="_blank" rel="noreferrer noopener" style={{ textDecoration: "none", color: "white" }}>
                      <FontAwesomeIcon icon={faUser} style={{ marginBottom: "1px", width: "15px" }} />
                    </a>
                  </div>
                </div>
                <Typography style={{ fontSize: "10px", color: "white" }}>
                  Price: {convertWeiToEth(nft.price.current.value)} ETH
                </Typography>
              </Container>
              </Box>
              </motion.div>
            </Grid>
          ))}
          {nfts && nfts.length === 0 && <Typography>Nessun NFT trovato</Typography>}
        </Grid>
      )}
        <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <img src={selectedImage} alt="NFT Preview" style={{ width: '100%' }} />
        </Box>
      </Modal>
    </Card>
  );
};

export default FeaturedProject;
