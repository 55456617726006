import { Container, Typography, useMediaQuery } from '@mui/material';
import React from 'react'

function CoinTool() {
  const mobileWidth = 600;
const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  return (
    <Container style={{
      display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"
    }}>

      <Typography style={{fontSize: "30px", color: 'white'}}>
        CoinTool
      </Typography>

    {!isMobile && (
<iframe src="https://cointool.app/eth/createToken?menu=1&contact=1&iframe=1" width="1150" height="600" frameborder="0"  />
    )}
</Container>
  )
}

export default CoinTool;