import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import React from "react";

const Loading = ({ label }) => {
  return (
    <Container style={{display: "flex", alignItems: "center", justifyContent: "center"}}>

      <Typography color="white" sx={{ mb: 3 }}>
        {label || ""}
      </Typography>
    </Container>
  );
};

export default Loading;
