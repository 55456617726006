import { Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {useMediaQuery} from "@mui/material";
const CreatePost = () => {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate("/posts/create")}
      sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        height: "25px",
        width: isMobile ? "100%" : '70%',
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
      }}
    >
      <span style={{color: 'white', fontWeight: 'bold'}}>NEW POST</span>
      <FontAwesomeIcon icon={faPlusCircle} style={{ color: 'white', marginLeft: '8px', }} />

    </Button>
  );
};

export default CreatePost;
