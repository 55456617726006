import { motion } from 'framer-motion';
import { Card, Typography, Button, Link, Container, useMediaQuery } from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faXTwitter } from '@fortawesome/free-brands-svg-icons'
import '../components/Home.css';
import discord from  "../assets/img/discordgif.gif";
const JoinComponent = () => {

    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <Container style={{flexDirection: "column",  width: isMobile ? "100%" : "800px", display: 'flex', alignItems: "center", justifyContent: 'center'}} className="neon-effect">
            <Typography style={{color: 'white', textAlign: "center", fontSize: "14px"}}>
                Restricted group of people living in web3.<br /> Take a seat and join us.
                </Typography>
               
                <div style={{display: 'flex', marginTop: "1rem"}}>
                <Button 
                    variant="contained"
                    style={{ width: "150px",
                    borderBottom: '3px solid #c100c4',
                    boxShadow: '0px 0px 3px 0px #c100c4',
                    borderTop: 'none',
                    borderLeft: "none",
                    borderRight: 'none',
                    borderRadius: "0.3rem" ,
                    color: 'white', backgroundColor: "black" }}
                    component={Link}
                    href="https://twitter.com/traphouse_gvng"
                >
                    Follow on <FontAwesomeIcon style={{marginLeft: '10px'}} icon={faXTwitter}/>
                </Button>
                <Button variant="contained"
style={{ width: "150px",
borderBottom: '3px solid #c100c4',
boxShadow: '0px 0px 3px 0px #c100c4',
borderTop: 'none',
borderLeft: "none",
borderRight: 'none',
borderRadius: "0.3rem" ,
color: 'white', backgroundColor: "black" }}
                    component={Link}
                    href="https://discord.gg/rHXtvpZAMs"
                >
                    Discord <img src={discord} alt='discordimg' style={{marginLeft: "10px", width: "20px"}}></img>
                </Button>
                </div>

            </Container>
        </motion.div>
    );
};

export default JoinComponent;
