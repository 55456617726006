import { Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import FindUsers from "./FindUsers";
import raffleAPI from "../api/raffle"; // Importa il file api/raffle
import TopHashtags from "./TopHashtags";
import UsefulLinks from "./UsefulLinks";
import Raffles from "./Raffles";
import NewPools from "./NewPools";
import FeatuerdProject from "../components/FeaturedProject";
import TokenSearch from "./TokenSearch";
import PollComponent from "./PoolComponent";

const Sidebar = () => {

  const [raffles, setRaffles] = useState([]);

  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const data = await raffleAPI.getRaffles();
        setRaffles(data.data.raffles); // Assumi che i dati siano formattati come nell'esempio della tua API
      } catch (error) {
        console.error("Errore nel recupero delle raffles:", error);
      }
    };

    fetchRaffles();
  }, []);

  return (
    <Stack spacing={2}>
      <TokenSearch />
      <FeatuerdProject />
      {/* <Raffles raffles={raffles} /> */}
      </Stack>
  );
};

export default Sidebar;
