import {
  Button,
  Card,
  Link,
  Stack,
  TextField,
  Select,
  MenuItem,
  Typography,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createPost } from "../api/posts";
import ErrorAlert from "./ErrorAlert";
import { isLoggedIn } from "../helpers/authHelper";
import gas from "../assets/img/face-logo.png";
import HorizontalStack from "./util/HorizontalStack";
import { useMediaQuery } from '@mui/material';
import UserAvatar from "./UserAvatar";
import GoBack from "./GoBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

const PostEditor = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState('');
  const [content, setContent] = useState("");
  const [hashtags, setHashtags] = useState([]); // Aggiungi questo stato
  const mobileWidth = 600; // Puoi regolare questo valore in base alle tue esigenze
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  const [formData, setFormData] = useState({
    content: "",
  });

  const [serverError, setServerError] = useState("");
  const [errors, setErrors] = useState({});
  const user = isLoggedIn();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  
    // Estrai gli hashtag dal contenuto
    const extractedHashtags = e.target.value.match(/#[^\s#]+/g) || [];
    setHashtags(extractedHashtags);
    
    const errors = validate();
    setErrors(errors);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = {
      ...formData,
      category,
    };
    const data = await createPost(postData, isLoggedIn());
    setLoading(false);
    if (data && data.error) {
      setServerError(data.error);
    } else {
      navigate("/posts/" + data._id);
    }
  };

  const validate = () => {
    const errors = {};

    return errors;
  };

    // Function to handle showing the formatting help card
    const [showFormattingHelp, setShowFormattingHelp] = useState(false);

    const toggleFormattingHelp = () => {
      setShowFormattingHelp(!showFormattingHelp);
    };
  
    const formattingHelp = (
      <Card style={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'black',
        alignItems: "center",
        textAlign: 'center',
        height: "80px",
        border: 'none',
        boxShadow: '0px 0px 15px 0px black',
      }}>
        <Typography style={{color: "white"}}>
        <h5 style={{fontWeight: 'bolder'}}> 
        to insert a link type: <code>![Title](paste the link here)</code> <br />
        to insert a picture type:   <code >![Image](paste the image url here)</code>         
          </h5>
       </Typography>

           </Card>
           
    );

  return (

<Card style={{
  backgroundColor: "black"
}}   sx={{ padding: isMobile ? '20px' : '20px' }}>
  <Stack spacing={isMobile ? 1 : 2}>
    {user && (
      <HorizontalStack
        style={{
          justifyContent: 'space-between',
          color: 'white',
          marginBottom: isMobile ? '10px' : 0
        }}
        spacing={2}
      >
<div style={{
  display: "flex",
  gap: '1rem',
  alignItems: "center",
  justifyContent: 'center'
}}>
  <UserAvatar width={30} height={30} username={user.username} />
  <Typography variant="h5">
    New post from {user.username}
  </Typography>
  {!isMobile && (
    <Typography>
      <Link
        style={{ color: "#c100c4", cursor: "pointer" }}
        onClick={toggleFormattingHelp}
      >
        [Helper]
      </Link>
    </Typography>
  )}
</div>
{!isMobile && (
  <img style={{
    width: '30px',
    objectFit: 'contain',
  }} src={gas} alt="gas"></img>
)}
</HorizontalStack>


        )}

        {showFormattingHelp && formattingHelp}

        <Box component="form" onSubmit={handleSubmit}>

    <Container style={{display: "flex", alignItems: "center"}}>
        
        <Typography style={{color: 'white', fontSize: "14px"}}>Category:</Typography>
        <Select
  label="Category"
  value={category}
  onChange={(e) => setCategory(e.target.value)}
  sx={{height: "30px",  width: "250px", borderBottom: "1px solid #c100c4", marginLeft: "10px", backgroundColor: 'black', boxShadow: '0px 0px 5px 0px #c100c4' }}
>
  <MenuItem style={{color: "#c100c4"}} value="NFT">NFT</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Crypto">Crypto</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Airdrop">Airdrop</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Ordinals">Ordinals</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Thread">Thread</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Premint">Premint</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Alphabot">Alphabot</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Giveaway">Giveaway</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="News">News</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Scam Alert">Scam Alert</MenuItem>
  <MenuItem style={{color: "#c100c4"}} value="Eyes On">Eyes On</MenuItem>
</Select>

  </Container>
<TextField
  sx={{          borderWidth: "1px",
  borderRadius: "0.3rem",
  backgroundColor: 'black', border: '1px solid #c100c4',    
  boxShadow: "0px 0px 5px 0px #c100c4", 
}}
  fullWidth
  label="Type something here.."
  multiline
  rows={3}
  name="content"
  margin="normal"
  onChange={(e) => {
    handleChange(e);
    setContent(e.target.value);
  }}
  value={content}
  error={errors.content !== undefined}
  helperText={errors.content}
  required
/>
{hashtags.length > 0 && (
  <Typography variant="caption" color="white">
    Hashtags: {hashtags.join(", ")}
  </Typography>
)}
<ErrorAlert error={serverError} />

          <ErrorAlert error={serverError} />
          <center>
          <Button
            type="submit"
            fullWidth
            disabled={loading}
            sx={{
              mt: 2,
              backgroundColor: '#c100c4',
              borderRadius: "0.4rem",
              border: "1px solid black",
              color: 'black',
              width: "200px",
              fontWeight: "bolder"
            }}
          >
            {loading ? <>Submitting</> : <>Send post!
            <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faPaperPlane}></FontAwesomeIcon>
            </>}
          </Button>
          </center>
        </Box>
      </Stack>
    </Card>
  );
};

export default PostEditor;