import axios from 'axios';

const BASE_URL = 'https://traphousewall-69bb9a961171.herokuapp.com'; // Aggiorna con l'URL del tuo server se necessario

const raffleAPI = {
  getRaffles: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/api/raffles`);
      return response.data;
    } catch (error) {
      console.error('Errore durante il recupero delle raffles:', error);
      throw error;
    }
  },
};

export default raffleAPI;
