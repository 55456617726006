import React, { useEffect, useState } from 'react';
import { Card, Select, MenuItem, FormControl, InputLabel, Typography, IconButton, Container } from '@mui/material';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import './Chart.css';
import Cookies from 'js-cookie';
import { GiChart, GiPodiumWinner } from 'react-icons/gi';
import HorizontalStack from './util/HorizontalStack';
import { MdRefresh } from 'react-icons/md';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarChart, faChartColumn, faChartSimple } from '@fortawesome/free-solid-svg-icons';

const Charts = () => {
  const [loading, setLoading] = useState(true);
  const defaultCoin = Cookies.get('selectedCoin') || 'BTC'; // Usa il valore salvato nei cookie o 'BTC' come default
  const [coin, setCoin] = useState(defaultCoin + 'USDT'); // Inizializza lo stato con il valore dai cookie
  const [data, setData] = useState([]);
  const [interval, setInterval] = useState('1h'); // Stato per l'intervallo temporale

  useEffect(() => {
    fetchChartData();
  }, [coin, interval]); // Aggiunto intervallo come dipendenza


  const fetchChartData = async () => {
    setLoading(true);
    try {
      let limit;
      switch (interval) {
        case '1m':
        case '3m':
        case '5m':
        case '15m':
        case '30m':
          limit = 30; // 30 punti di dati per gli intervalli più brevi
          break;
        case '1h':
        case '2h':
        case '4h':
          limit = 24; // 24 punti di dati per le ore in un giorno
          break;
        case '6h':
        case '12h':
          limit = 12; // 12 punti di dati per intervalli di mezza giornata
          break;
        case '1d':
        case '3d':
          limit = 1; // 1 punto di dati per il giorno corrente
          break;
        case '1w':
          limit = 7; // 7 punti di dati per i giorni in una settimana
          break;
        case '1M':
          limit = 6; // 6 punti di dati per il mese corrente
          break;
        default:
          limit = 30;
      }

      const response = await axios.get(`https://api.binance.com/api/v3/klines?symbol=${coin}&interval=1d&limit=${limit}`);

      const chartData = response.data.map(item => ({
        date: new Date(item[0]).toLocaleDateString(),
        price: parseFloat(item[4]),
      }));
      setData(chartData);
    } catch (error) {
      console.error('Error fetching chart data:', error);
    }
    setLoading(false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Typography style={{ backgroundColor: 'black', display: "flex", 
        flexDirection: 'column',
        padding: '10px', border: '1px solid #999', borderRadius: '5px' }}>
        {`${label} - ${payload[0].value}`}
        </Typography>
      );
    }
  
    return null;
  };

  const handleCoinChange = (e) => {
    const newCoin = e.target.value;
    setCoin(newCoin + 'USDT');
    Cookies.set('selectedCoin', newCoin); // Salva la selezione nei cookie
  };

  return (
    <Card style={{ 
      width: '400px',
    padding: "15px", borderRadius: "0.5rem", border: "1px ridge grey", background: 'black' }}>
   <HorizontalStack style={{ backgroundColor: "black", padding: "8px", borderRadius: "0.4rem", display: 'flex', justifyContent: 'space-between' }}>
    
    <span style={{ color: 'white', fontSize: "12px"}}>PRICE CHART$
    <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faChartSimple}></FontAwesomeIcon>
    </span>
    <Typography style={{fontSize: '12px', display: "flex", alignItems: "center"}}>
    <MdRefresh style={{marginRight: "10px"}} onClick={fetchChartData} />
      Refresh        
    </Typography>

  </HorizontalStack>
                 <HorizontalStack style={{ backgroundColor: "black", 
                               height: "50px", 
          marginTop: "1rem", borderRadius: "0.4rem", display: 'flex', justifyContent: 'space-between' }}>

    <FormControl style={{
              width: "100%", border: "1px solid #c100c4",
              borderRadius: "0.5rem",
      display: "flex", alignItems: "center"}}>
    <InputLabel style={{height: "50px",color: "white"}} id="coin-select-label">Ticker</InputLabel>
    <Select
          labelId="coin-select-label"
          value={coin.replace('USDT', '')} // Rimuovi 'USDT' per il valore mostrato
          label="Coin"
          style={{ color: "#c100c4", width: "100%",
          fontSize: "12px" }}
          onChange={handleCoinChange}
        >
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="BTC">Bitcoin [BTC]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="ETH">Ethereum [ETH]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="SOL">Solana [SOL]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="BNB">Binance Coin [BNB]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="SEI">Sei[SEI]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="INJ">Injective [INJ]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="MATIC">Matic[MATIC]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="XRP">Ripple[XRP]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="APE">Ape Coin [APE]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="DOGE">Doge Coin [DOGE]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="ARB">Arbitrium [ARB]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="AVAX">Avalanche [AVAX]</MenuItem>
          <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}} value="PEPE">Pepe Coin [PEPE]</MenuItem>


        </Select>
      </FormControl>

      <FormControl style={{ width: "100%", border: "1px solid #c100c4",
              borderRadius: "0.5rem", display: "flex", alignItems: "center" }}>
    <InputLabel style={{ color: "white" }} id="interval-select-label">Interval</InputLabel>
    <Select
      labelId="interval-select-label"
      value={interval}
      label="Interval"
      style={{ width: "100%", fontSize: "12px" }}
      onChange={e => setInterval(e.target.value)}
    >
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="1m">1 minute</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="3m">3 minutes</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="5m">5 minutes</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="15m">15 minutes</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="30m">30 minutes</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="1h">1 hour</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="2h">2 hours</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="4h">4 hours</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="6h">6 hours</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="12h">12 hours</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="1d">1 day</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="3d">3 days</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="1w">1 week</MenuItem>
      <MenuItem style={{backgroundColor: '#c100c4', border: "2px solid black"}}  value="1M">1 month</MenuItem>
    </Select>
  </FormControl>

</HorizontalStack>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <ResponsiveContainer 
        className='chartBackground'
        height={200} style={{ 
          borderRadius: "0.5rem",
          background: 'https://i.postimg.cc/YShKd8fx/chartbg.png',
          backgroundColor: 'black', marginTop: "1rem" }}>
          <LineChart data={data} margin={{ top: 15, right: 20, left: 0, bottom: 5 }}>
            <XAxis className='xAxis-date' dataKey="date" stroke="white" />
            <YAxis className='yAxis-prices' domain={['auto', 'auto']} stroke="white" />
      <Tooltip content={<CustomTooltip />} /> {/* Utilizzo del Tooltip personalizzato */}
            <CartesianGrid stroke="grey" strokeDasharray="0" />
            <Line type="monotone" dataKey="price" stroke="#c100c4" strokeWidth={2} dot={false} className="neonGlowLine" />
          </LineChart>
        </ResponsiveContainer>
      </motion.div>
      )}
    </Card>
  );
};

export default Charts;
