import { Container, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PostCard from "../PostCard";
import Comments from "../Comments";
import Loading from "../Loading";
import ErrorAlert from "../ErrorAlert";
import { useParams } from "react-router-dom";
import { getPost } from "../../api/posts";
import { useMediaQuery } from "@mui/material";
import { isLoggedIn } from "../../helpers/authHelper";

const PostView = () => {
  const params = useParams();
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [post, setPost] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const user = isLoggedIn();

  useEffect(() => {
    document.title = `Comments - TRAP HOU$E Wall`;
    return () => {
      document.title = "TRAP HOU$E - Home";
    };
  }, []);

  const fetchPost = async () => {
    setLoading(true);
    const data = await getPost(params.id, user && user.token);
    if (data.error) {
      setError(data.error);
    } else {
      setPost(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPost();
  }, [params.id]);

  return (
    <Container style={{ maxWidth: isMobile ? '100%' : '900px', margin: 'auto' }}>
      {loading ? (
        <Loading />
      ) : post ? (
        <div>
          <PostCard post={post} key={post._id} />
          <Typography style={{textAlign: "center", fontSize: "30px", color: "white"}}>
                Comments
          </Typography>
          <Comments />
        </div>
      ) : (
        error && <ErrorAlert error={error} />
      )}
    </Container>
  );
};

export default PostView;
