import Copyright from "./Copyright";
import { Container, Typography, useMediaQuery } from "@mui/material";
import React, {useState, useEffect} from "react";
import axios from "axios";

const Footer = () => {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [connectedUsers, setConnectedUsers] = useState(0);
  const [serverStatus, setServerStatus] = useState('offline');

  useEffect(() => {
    const fetchConnectedUsers = async () => {
      try {
        const response = await axios.get('https://traphousewall-69bb9a961171.herokuapp.com/api/connected-users');
        setConnectedUsers(response.data.connectedUsers);
      } catch (error) {
        console.error('Errore nel recuperare il numero di utenti connessi:', error);
      }
    };

    fetchConnectedUsers();

    const fetchServerStatus = async () => {
      try {
        const response = await axios.get('https://traphousewall-69bb9a961171.herokuapp.com/api/server-status');
        setServerStatus(response.data.status);
      } catch (error) {
        console.error('Errore nel verificare lo stato del server:', error);
        setServerStatus('offline');
      }
    };

    fetchConnectedUsers();
    fetchServerStatus();
    const interval = setInterval(() => {
      fetchConnectedUsers();
      fetchServerStatus();
    }, 5000);

    return () => clearInterval(interval);
  }, []);
  return (
    <Container style={{
      position: 'fixed',
      display: "flex",
      alignItems: "center",
      bottom: 0,
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: 'black', // Scegli un colore di sfondo adatto
      color: 'white', // Scegli un colore di testo adatto
      borderTop: '1px ridge white',
      textAlign: 'center',
      padding: '5px'
    }}>
            <Typography style={{fontSize: "14px", color: "white"}} className={`server-status ${serverStatus}`}>
        Status: 
        <span style={{marginLeft: "10px",  fontSize:"14px"}} className="status-icon"></span>
      </Typography>

      <Typography style={{  fontSize: "14px", color: "white"}}>
      Users: {connectedUsers}
        </Typography>         
        </Container>
  );
};

export default Footer;
