// Crea un nuovo file, ad esempio, newPoolsAPI.js

import axios from 'axios';

const BASE_URL = 'https://traphousewall-69bb9a961171.herokuapp.com';

const tokenSearch = {
  getTokenByAddress: async (network) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/new-pools/${network}`);
      return response.data;
    } catch (error) {
      console.error('Errore durante il recupero delle nuove pools:', error);
      throw error;
    }
  },
};

export default tokenSearch;
