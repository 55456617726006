import React, { useState } from 'react';
import { Typography, Modal, Box, Container } from "@mui/material";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import "./markdown.scss";

const Markdown = ({ content }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  const shortenUrl = (url, maxLength = 30) => {
    if (url.length > maxLength) {
      return `${url.substring(0, maxLength - 3)}...`; // Tronca e aggiungi '...'
    }
    return url;
  };

  return (
    <Container style={{display: "flex", fontSize: "12px"}}>
      <ReactMarkdown
        className="markdown"
        remarkPlugins={[remarkGfm]}
        components={{
          a: ({ node, ...props }) => (
            <a style={{fontSize: '12px'}} href={props.href} target="_blank" rel="noopener noreferrer">
              {shortenUrl(props.href)}
            </a>
          ),
          img: ({ node, ...props }) => (
            <img 
              src={props.src} 
              alt={props.alt} 
              onClick={() => openModal(props.src)} 
              style={{cursor: 'pointer', maxWidth: '100%', height: 'auto'}}
            />
          ),
          // Aggiungi qui altri componenti personalizzati se necessario
        }}
        children={content}
      />
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <img src={selectedImage} alt="Modal" style={{width: '1200px', objectFit: 'contain', height: 'auto'}} />
        </Box>
      </Modal>
    </Container>
  );
};

export default Markdown;
