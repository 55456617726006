import React, { useEffect, useState, useRef } from "react";
import { Stack, Typography, Box, Button, useMediaQuery, Container } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { getPosts, getUserLikedPosts } from "../api/posts";
import { getUser } from "../api/users";
import Loading from "./Loading";
import PostCard from "./PostCard";
import house from "../assets/img/logo-banner.png";
import PostActions from "./PostActions";
import { isLoggedIn } from "../helpers/authHelper";
import "../components/views/scrollPost.css";
import PostCardPreview from "./PostCardPreview";
import './Chart.css';

const PostBrowser = (props) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [end, setEnd] = useState(false);
  const [sortBy, setSortBy] = useState("-createdAt");
  const [count, setCount] = useState(0);
  const [search] = useSearchParams();
  const [effect, setEffect] = useState(false);
  const [postCount, setPostCount] = useState(0);

  const containerRef = useRef(null);
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const searchExists =
    search && search.get("search") && search.get("search").length > 0;




  const fetchPosts = async () => {
    setLoading(true);
    const newPage = page + 1;
    let query = { page: newPage, sortBy };
    let data;

    if (props.contentType === "posts") {
      if (props.profileUser) query.author = props.profileUser.username;
      if (searchExists) query.search = search.get("search");
      data = await getPosts(isLoggedIn() && isLoggedIn().token, query);
    } else if (props.contentType === "liked") {
      data = await getUserLikedPosts(props.profileUser._id, isLoggedIn() && isLoggedIn().token, query);
    }

    if (data.data.length < 10) {
      setEnd(true);
    }
    setLoading(false);
    if (!data.error) {
      setPosts([...posts, ...data.data]);
      setCount(data.count);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [sortBy, effect, page]);

  useEffect(() => {
    setPosts([]);
    setPage(0);
    setEnd(false);
    setEffect(!effect);
  }, [search]);

  const handleSortBy = (e) => {
    const newSortBy = e.target.value;
    setPosts([]);
    setPage(0);
    setEnd(false);
    setSortBy(newSortBy);
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div ref={containerRef} style={{
      overflowY: "auto", height: isMobile ? undefined : "calc(100vh - 100px)" }} className="hide-scrollbar">

            <Container style={{display: 'flex', alignItems: "center", justifyContent: "space-between"}}>

            <span style={{color: "white"}}>Latest posts</span>

            <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        display: 'flex',
        height: "25px",
        width: "120px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>POST: {count}
</Button>

            </Container>

      {posts.map((post) => (
        <PostCardPreview
          preview="primary"
          key={post._id}
          post={post}
          removePost={() => setPosts(posts.filter((p) => p._id !== post._id))}
        />
      ))}

      {loading && <Loading />}
      {end ? (
        <Stack alignItems="center">
          <Typography variant="h5" color="white" style={{ padding: "10px", borderRadius: '1rem' }} gutterBottom>
            {posts.length > 0 ? "No new posts." : "No post available."}
            <Button style={{ backgroundColor: '#c100c4', borderBottom: "3px solid black", boxShadow: "0px 0px 3px 0px white", color: 'black', fontWeight: "bold" }} size="small" onClick={handleBackToTop}>
              Back to top
            </Button>
          </Typography>
        </Stack>
      ) : (
        !loading && posts.length > 0 && (
          <Stack pt={2} pb={6} alignItems="center" spacing={2}>
            <Button onClick={fetchPosts} variant="contained">
              Load more
            </Button>
            <Button variant="text" size="small" onClick={handleBackToTop}>
              Back to top
            </Button>
          </Stack>
        )
      )}
    </div>
  );
};

export default PostBrowser;
