import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Card, CardContent, Button, Dialog, useMediaQuery, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Container } from '@mui/material';
import { motion } from 'framer-motion';
import notask from "../assets/img/notask.png";
import memologo from "../assets/img/taskicon.png";
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import facelogo from "../assets/img/face logo.png";
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from "../helpers/authHelper";

const MemoPage = () => {
  
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [memos, setMemos] = useState([]);
  const [filteredMemos, setFilteredMemos] = useState([]);
  const [isDeletingAll, setIsDeletingAll] = useState(false);
  const [title, setTitle] = useState('');
  const [image, setImage] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [memoToDelete, setMemoToDelete] = useState()
  const [isTaskManagerVisible, setIsTaskManagerVisible] = useState(true);
  const [isXFollowVisible, setXFollowVisible] = useState(false);
  const navigate = useNavigate();
  const user = isLoggedIn();

  

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Reindirizza a /join se l'utente non è autenticato
    }
  }, [user, navigate]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval); 
    };
  }, []);


  useEffect(() => {
    const savedMemos = Cookies.get('memos');
    if (savedMemos) {
      const loadedMemos = JSON.parse(savedMemos);
      setMemos(loadedMemos);
      setFilteredMemos(loadedMemos);
    }
    // const XShown = Cookies.get('xShown');
    // if (!XShown) {
    //   setXFollowVisible(true);
    // }
  }, []);

  useEffect(() => {
    const results = memos.filter(memo => memo.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
     memo.description.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredMemos(results);
  }, [searchTerm, memos]);


  const handleCloseXFollow = () => {
    Cookies.set('xShown', 'true', { expires: 365 });
    setXFollowVisible(false);
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const newMemo = { 
      title, 
      description, 
      image,
      link
    };
    
    if (!title || title.trim() === '') {
      setErrorMessage("You must provide a name for the task.");
      setIsErrorDialogOpen(true);
      return;
    }
  
    const updatedMemos = [...memos, newMemo];
    saveMemos(updatedMemos); // Aggiornato per passare updatedMemos
    setTitle('');
    setDescription('');
    setImage('');
    setLink('');
  };


  const saveMemos = (newMemos) => {
    setMemos(newMemos);
    Cookies.set('memos', JSON.stringify(newMemos), { expires: 7 });
  };
  

  const deleteMemo = (index) => {
    const updatedMemos = memos.filter((_, i) => i !== index);
    saveMemos(updatedMemos);
    setOpenDialog(false);
  };

  const deleteAllMemos = () => {
    if (isDeletingAll) {
      saveMemos([]);
      setIsDeletingAll(false);
    }
    setOpenDialog(false);
  };

  const toggleTaskManager = () => {
    setIsTaskManagerVisible(prevState => !prevState);
  };

  

const handleOpenDialog = (index) => {
  if (index === null) {
    setIsDeletingAll(true);
  } else {
    setMemoToDelete(index);
    setIsDeletingAll(false);
  }
  setOpenDialog(true);
};

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setMemoToDelete(null);
  };


  return (

    <>

    <Container style={{display: 'flex', justifyContent: "center", alignItems: "center",
     flexDirection: "column"}}>
          <Container style={{alignItems: "center", flexDirection: isMobile ? 'column' : undefined, 
        display: "flex", justifyContent: 'space-between',
        backgroundColor: 'rgba(0,0,0, 0.85)',
         padding: "15px"}}>
                  
                  <Typography style={{
                    display: 'flex', alignItems: "center", 
                    justifyContent: "center",
        backgroundColor: '#0B0B0B', padding: '15px', borderRadius: "0.3rem",
        borderBottom: '3px solid rgba(172,2,90, 0.5)',
        boxShadow: '0px 3px 5px black',
        color: 'grey', fontSize: '10px'}}>
                  ➜ DISCLAIMER | 
                    Memos saved via cookies. NO DATA collected from us.

                   </Typography>

<Typography style={{marginLeft: "5px", color: 'white', fontSize: "14px"}}>
          Your Memo: {filteredMemos.length}
        </Typography>
  <Typography style={{fontSize: "14px", color: 'white',
marginRight: "5px", }}>
Time: {currentTime ? currentTime.toLocaleTimeString() : ''}
  </Typography>

                   </Container>

      {filteredMemos.length > 0 ? (
      <Container style={{display: 'none'}}>

      </Container>
      ): (

        <div style={{display: "none"}}></div>
      )}



      
      <Container style={{
      display: 'flex', 
      height: isMobile ? '100%' : '520px',
      width: isMobile ? '100%' : '100%',
      flexDirection: isMobile ? "column" : undefined,
      justifyContent: "center",
      gap: "2rem",
    }}>



      {/* //////  MEMO EDITOR QUANDO VISIVBILE */}
<motion.div
            initial={false}
            animate={{ 
              x: isTaskManagerVisible ? 0 : -300,
              opacity: isTaskManagerVisible ? 1 : 0
            }}
            transition={{ duration: 0.5 }}
            style={{
              width: isMobile ? 'auto' : "500px", 
              borderRadius: "0.5rem",
            }}
          >
          {isTaskManagerVisible && (
          
            <form onSubmit={handleSubmit} style={{
              display: "flex", padding: "15px", gap: "1rem",
              backgroundColor: '#010101', border: '1px groove white',    
              boxShadow: "0px 0px 12px 0px #c100c4", 
              height: isMobile ? 'auto' : '520px',
              width: '100%', borderRadius: "0.5rem",
          flexDirection: 'column', justifyContent: "center"}}>
                <Typography style={{fontSize: "14px", color: 'white', textAlign: "center"}}>
                  Memo editor
                </Typography>
            <Typography style={{backgroundColor: '#0e0e0e', padding: '3px', 
              fontWeight: 'bold',  fontSize: "12px", color: '#c100c4'}}>
              ◆ NAME
            </Typography>
            <textarea
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={inputStyle(title, 'title')}
              placeholder="Insert a title for the Memo.."
            />
                  <Typography style={{backgroundColor: '#0e0e0e', padding: '3px', 
                  fontWeight: 'bold',
                  fontSize: "12px", color: '#c100c4'}}>
                  ◆ description
                  </Typography>
                    <textarea
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      style={inputStyle(description, 'description')}
                      placeholder="Type here.."
                    />
                     <Typography style={{backgroundColor: '#0e0e0e', padding: '3px', 
                  fontWeight: 'bold', marginTop: '0.5rem',
                  fontSize: "12px", color: '#c100c4'}}>
                  ◆ link
                  </Typography>
                    <textarea
                      value={link}
                      onChange={(e) => setLink(e.target.value)}
                      style={inputStyle(image, 'image')}
                      placeholder="Paste an external URL.."
                    />
                  <Typography style={{backgroundColor: '#0e0e0e',
                   padding: '3px', 
                  fontWeight: 'bold', marginTop: '0.5rem',
                  fontSize: "12px", color: '#c100c4'}}>
                  ◆ image
                  </Typography>
                    <textarea
                      value={image}
                      onChange={(e) => setImage(e.target.value)}
                      style={inputStyle(image, 'image')}
                      placeholder="paste URL of the image.."
                    />
               <Button style={{width: "100%",
                         borderBottom: '3px solid #c100c4',
                         boxShadow: '0px 0px 3px 0px #c100c4',
                         borderTop: 'none',
                         borderLeft: "none",
                         borderRight: 'none',
                         borderRadius: "0.3rem" ,
               color: 'white', backgroundColor: "#c100c4"}}
              type="submit">Create</Button>
          </form>       
                    )}
          </motion.div>

        

<div className="custom-scrollbar" style={{width: isTaskManagerVisible ? '100%' : isMobile ? "auto" : '700px', 
overflowY: 'auto', padding: "15px", display: 'flex', flexDirection: "column",
gap: "0.8rem",
  borderRadius: "0.3rem", boxShadow: "0px 0px 10px 0px #c100c4",  
  backgroundColor: 'rgba(14,14,14, 0.8)' }}>

<Container style={{display: 'flex', 
width: isMobile ? '100%' : undefined,
justifyContent: "space-between", borderRadius: '0.3rem',
    boxShadow: '0px 0px 3px #c100c4',
padding: '10px', backgroundColor: "black", alignItems: 'center'}}>
<textarea
  placeholder='Search...'
  style={{
    height: "40px",
    fontSize: "20px",
    fontWeight: "bold",
    padding: "5px",
    backgroundColor: 'black',
    borderBottom: '1px solid #c100c4',
    boxShadow: '0px 1px 3px 0px #c100c4',
    borderTop: 'none',
    borderLeft: "none",
    borderRight: 'none',
    borderRadius: "0.3rem" ,
    fontFamily: 'Proto',
    color: 'white',
    width: isMobile ? '70%' : undefined,
    outline: 'none'
  }}
  onChange={(e) => setSearchTerm(e.target.value)} 
/>


<div style={{display: 'flex', justifyContent: 'center', gap: "1rem"}}>

              {filteredMemos.length > 0 && (

                    <Button 
                    data-tooltip-id="killbtn"
                    data-tooltip-content="Delete all your task"
                   style={{backgroundColor: '#0f0f0f', 
          color: 'white', height: "30px", 
          borderBottom: '1px solid #c100c4',
          boxShadow: '0px 1px 3px 0px #c100c4',
          borderTop: 'none',
          borderLeft: "none",
          borderRight: 'none',
          borderRadius: "0.3rem" }} 
                      variant="contained" color="secondary"
                      onClick={() => handleOpenDialog(null)}>kill</Button>
                  )}

<Button style={{backgroundColor: '#0f0f0f', 
          color: 'white', height: "30px", 
          borderBottom: '1px solid #c100c4',
          boxShadow: '0px 1px 3px 0px #c100c4', 
          borderTop: 'none',
          borderLeft: "none",
          borderRight: 'none',
          borderRadius: "0.3rem" }} 
       onClick={toggleTaskManager}>{isTaskManagerVisible ? "CLOSE" : "NEW"}
       {!isTaskManagerVisible && (
               <img src={memologo} alt='memologo'
          style={{width: "15px", marginLeft: "10px", objectFit: 'cover'}}></img>)}
       </Button>

</div>
        </Container>
  {filteredMemos.length > 0 ? (
    
    filteredMemos.map((memo, index) => (
      <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>

        <Card style={{boxShadow: "0px 0px 5px 0px white", borderRadius: '0.3rem',  backgroundColor: '#c100c4' }}>
          <CardContent>
            <Container style={{display: 'flex', justifyContent: 'space-between', 
             backgroundColor: "black", 
             borderBottom: '3px solid rgba(172,2,90, 0.5)',
    boxShadow: '0px 3px 5px black',
    borderTop: 'none',
    borderLeft: "none",
    borderRight: 'none',
    borderRadius: "0.3rem",

             padding: "0px", alignItems: "center"}}>
            <Typography style={{ marginLeft: "10px",
              color: "white"}}>|➜
                <span style={{marginLeft: "10px", color: "#c100c4", fontSize: "16px"}}>
                 {memo.title}
                </span>
            </Typography>
            <Typography style={{cursor: 'pointer', color: "white", fontSize: "14px", padding: "10px",
            borderRadius: '0.2rem', fontWeight: "bolder"}}
             onClick={() => handleOpenDialog(index)}>X</Typography>
            </Container>
            {memo.description ? (
            <Typography style={{fontSize: "16px", marginTop: "1rem", 
                color: "black"}}>
                <span style={{marginLeft: "10px", color: "white", 
                fontWeight: 'bold',
                fontSize: "16px"}}>
                  {memo.description}
                </span>
            </Typography>
            ) : (
              null
            )}
                        {memo.link ? (
            <Typography style={{fontSize: "16px", marginTop: "1rem", 
            color: "black"}}>
    Saved Link:  ➜
    <span style={{marginLeft: "10px", color: "white", 
                fontWeight: 'bold',
                fontSize: "16px"}}>
    <a href={memo.link} style={{ textDecoration: 'underline', marginLeft: "10px", color: "white", fontSize: "14px" }}>
    VISIT 
    </a>
    </span>
</Typography>
                        ) : (
                          null
                        )
}


            {memo.image ? (
  <Typography style={{fontSize: "14px", marginTop: "0.5rem"}}>
    <a href={memo.image}>
      <img src={memo.image} alt='memo-img' style={{
            borderBottom: '3px solid rgba(172,2,90, 0.5)',
            boxShadow: '0px 3px 5px black',
        width: isMobile ? '50px' : "80px", objectFit: 'contain', borderRadius: "0.5rem"}}>
      </img>
    </a>
  </Typography>
) : null}

          </CardContent>
        </Card>
        <div></div>
      </motion.div>
    ))
  ) : (
    <div style={{ display: 'flex',  flexDirection: 'column',
         alignItems: 'center'}}>
            <Typography style={{fontSize: "25px", color: 'white',
            borderBottom: '3px solid rgba(172,2,90, 0.5)',
            backgroundColor: 'black',
            boxShadow: '0px 3px 5px black',
            borderTop: 'none',
            padding: "10px",
            borderLeft: "none",

            borderRight: 'none',
            borderRadius: "0.3rem",}}>
NOT FOUND
</Typography>


      <img src={notask} alt="No task" style={{marginTop: "0.5rem",
       maxWidth: isMobile ?  '80%' : '40%', maxHeight: isMobile? '80%' : '40%' }} />

    </div>
  )}
</div>
<Tooltip id='killbtn'></Tooltip>

         

      </Container>

  

      {isXFollowVisible && (
        <Dialog open={isXFollowVisible} onClose={handleCloseXFollow}>
          <a className="twitter-follow-button" data-size="large"
  href="https://twitter.com/traphouse_gvng">
Follow @TRAP HOUSE</a>
        </Dialog>
      )}


      <Dialog open={isErrorDialogOpen} onClose={() => setIsErrorDialogOpen(false)}>
  <DialogTitle>Error 000 -  (╯°□°)╯</DialogTitle>
  <DialogContent>
    <DialogContentText>{errorMessage}</DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setIsErrorDialogOpen(false)} color="primary">Ok</Button>
  </DialogActions>
</Dialog>


      <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle style={{color: 'white'}}>{isDeletingAll ? "TASKS RESET" : "Delete memo"}</DialogTitle>
      <DialogContent>
        <DialogContentText style={{color: 'white'}}>
          {isDeletingAll ? "Do you want to delete ALL your memos?" : "Do you want to delete this memo?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button  sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        color: "white",
        height: "25px",
        width: isMobile ? "100%" : '70%',
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
      }} onClick={handleCloseDialog}>Cancel</Button>
        {isDeletingAll 
          ? <Button   sx={{
            backgroundColor:"#c100c4",
            fontSize: "12px",
            height: "25px",
            color: "white",
            width: isMobile ? "100%" : '70%',
            border: '1px groove white',    
            boxShadow: "0px 0px 5px 0px #c100c4",
            borderRadius: '4px',
          }} onClick={deleteAllMemos} color="secondary">Delete all</Button>
          : <Button  sx={{
            backgroundColor:"#c100c4",
            fontSize: "12px",
            height: "25px",
            color: "white",
            width: isMobile ? "100%" : '70%',
            border: '1px groove white',    
            boxShadow: "0px 0px 5px 0px #c100c4",
            borderRadius: '4px',
          }} onClick={() => deleteMemo(memoToDelete)} color="secondary">Delete</Button>

        }
      </DialogActions>
    </Dialog>
    </Container>
    </>
  );
};

const inputStyle = (text, type) => {

  let baseStyle = {
    width: '100%',
    height: "28px",
    padding: '5px',
    backgroundColor: 'rgba(0,0,0,0.85)',
    border: "none", 
    borderBottom: '1px solid #c100c4',
    color: text ? 'white' : '#c100c4', 
    fontSize: '14px',
  };

  if (type === 'description') {
    baseStyle = {
      ...baseStyle,
      height: "28px"
    };
  } else if (type ==='image'){
    baseStyle = {
      ...baseStyle,
      height: "28px"
    };
  }

  return baseStyle;
};


export default MemoPage;
