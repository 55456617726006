import { Avatar, Typography } from "@mui/material";
import React from "react";
import HorizontalStack from "./util/HorizontalStack";
import Moment from "react-moment";
import moment from 'moment';


import UserAvatar from "./UserAvatar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClockFour, faTimes, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function formatDuration(createdAt) {
  
  const duration = moment.duration(moment().diff(moment(createdAt)));
  if (duration.asSeconds() < 60) {
    return `${Math.floor(duration.asSeconds())}s`;
  } else if (duration.asMinutes() < 60) {
    return `${Math.floor(duration.asMinutes())}m`;
  } else if (duration.asHours() < 24) {
    return `${Math.floor(duration.asHours())}h`;
  } else if (duration.asDays() < 30) {
    return `${Math.floor(duration.asDays())}d`;
  } else if (duration.asMonths() < 12) {
    return `${Math.floor(duration.asMonths())}mo`;
  } else {
    return `${Math.floor(duration.asYears())}y`;
  }
}


const ContentDetails = ({ username, createdAt, edited, preview }) => {
  return (
    <HorizontalStack>
      <UserAvatar width={20} height={20} username={username} />

      <Typography variant="subtitle" color="text.secondary" gutterBottom>
        <Link
          style={{
            color: "#c100c4",
            fontSize: "12px",
            fontWeight: 'bold', 
          }}
          underline="hover"
          onClick={(e) => {
            e.stopPropagation();
          }}
          to={"/users/" + username}
        >
        {username} 
        
        </Link>

        {!preview && (
          <>
            {" "}
            <span style={{fontSize: "12px",  fontWeight: 'bold', marginLeft: "5px", color: "white"}}>
            •
         </span>

            <span style={{color: 'white',fontSize: "12px", marginLeft: "5px"}}>
               {formatDuration(createdAt)}
            </span>

            
            <FontAwesomeIcon style={{color: "white", marginLeft: "6px"}} icon={faClockFour}></FontAwesomeIcon>
             {/* {edited && <>
             
              <span style={{color: '#19A5F7', fontSize: '10px',marginLeft: '8px'}}>(Edited)</span>
            
              </>
              } */}

          </>
        )}
      </Typography>
    </HorizontalStack>
  );
};

export default ContentDetails;
