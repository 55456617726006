import { Container, Stack } from "@mui/material";
import React from "react";
import GridLayout from "../GridLayout";
import PostBrowser from "../PostBrowser";
import Sidebar2 from "../Sidebar2";

const SearchView = () => {
  React.useEffect(() => {
    document.title = `Search Post`;
    return () => {
      // Reimposta il titolo quando il componente viene smontato
      document.title = "TRAP HOU$E - Home";
    };
  }, []);
  return (
    <Container>
      <GridLayout
        left={
          <Stack spacing={2}>
            <PostBrowser createPost contentType="posts" />
          </Stack>
        }
        right={<Sidebar2 />}
      />
    </Container>
  );
};

export default SearchView;
