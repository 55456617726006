import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import Raffles from "../components/Raffles";
import raffleAPI from "../api/raffle"; // Importa il file api/raffle
import UsefulLinks from "../components/UsefulLinks";
import FeatuerdProject from "../components/FeaturedProject";
import Charts from "../components/Charts";
const Sidebar2 = () => {
  const [raffles, setRaffles] = useState([]);

  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const data = await raffleAPI.getRaffles();
        setRaffles(data.data.raffles); // Assumi che i dati siano formattati come nell'esempio della tua API
      } catch (error) {
        console.error("Errore nel recupero delle raffles:", error);
      }
    };

    fetchRaffles();
  }, []);

  return (
    <Stack spacing={2}>
      <Charts />

    </Stack>
  );
};

export default Sidebar2;
