import React, { useState } from 'react';
import axios from 'axios';
import { 
  Card, 
  TextField, 
  Button, 
  Typography, 
  CircularProgress,
  List,
  useMediaQuery,
  Link,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
  Container
} from '@mui/material';
import HorizontalStack from './util/HorizontalStack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple, faDollarSign, faExchange } from '@fortawesome/free-solid-svg-icons';
import { MdRefresh } from 'react-icons/md';
import {faLink } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

const TokenSearch = () => {
  const [tokenAddress, setTokenAddress] = useState('');
  const [tokenData, setTokenData] = useState(null);
  const [loading, setLoading] = useState(false);
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const supportedChains = ['eth', 'solana', 'sei-network', 'injective', 'bsc', 'polygon_pos', 'avax', 'arbitrum', 'base', 'optimism', 'zksync', 'mantle', 'manta', 'canto', 'cro', 'aptos', 'starknet-alpha'];


   // Stili Neon
   const neonStyle = {
    textShadow: '0 0 8px rgba(255, 255, 255, 0.7)',
    color: '#c100c4',
    boxShadow: '0 0 5px rgba(193, 0, 196, 0.75)'
  };


  const fetchTokenData = async () => {
    setLoading(true);
    setTokenData(null);

    for (const network of supportedChains) {
      try {
        const response = await axios.get(`https://traphousewall-69bb9a961171.herokuapp.com/api/token-info/${network}/${tokenAddress}`);
        if (response.data && response.data.data && response.data.data.attributes) {
          // Se troviamo un token corrispondente in una chain, interrompiamo il ciclo
          setTokenData(response.data.data.attributes);
          break;
        }
      } catch (error) {
        console.log(`Token non trovato in ${network}:`, error);
      }
    }

    if (!tokenData) {
      console.error("Token non trovato in nessuna chain supportata.");
    }
    setLoading(false);
  };

  return (
    <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
  >
    <Card style={{ display: "flex", flexDirection: "column",
      padding: "15px", borderRadius: "0.5rem", border: "1px ridge grey", background: 'black' }}>
     <HorizontalStack style={{ backgroundColor: "black", padding: "8px", borderRadius: "0.4rem", display: 'flex', justifyContent: 'space-between' }}>
      
      <span style={{ color: 'white', fontSize: "12px"}}>TOKEN $EARCH & SWAP
      <FontAwesomeIcon style={{marginLeft: "10px"}} icon={faExchange}></FontAwesomeIcon>
      </span>
      <Typography style={{fontSize: '12px', display: "flex", alignItems: "center"}}>
      <MdRefresh style={{marginRight: "10px"}} onClick={fetchTokenData} />
        Refresh        
      </Typography>
  
    </HorizontalStack>
    
    <Typography style={{marginTop: '0.5rem',
      color: "white", fontSize: '12px', textAlign: 'center'
    }}>

Search for a token by address on 10+ chains.
    </Typography>


    <HorizontalStack style={{ backgroundColor: "black", 
                               height: "50px", padding: "10px",
          marginTop: "0.5rem", borderRadius: "0.4rem", display: 'flex', justifyContent: 'space-between' }}>

<TextField
        label="Paste address.."
        value={tokenAddress}
        onChange={(e) => setTokenAddress(e.target.value)}
        sx={{border: "1px solid grey", borderRadius: "0.5rem",height: "50px", width: "100%", fontSize: "12px"}}
      />

<Button 
       sx={{
        backgroundColor:"#c100c4",
        fontSize: "14px",
        height: "25px",
        width: isMobile ? "auto" : 'auto',
        border: '1px groove white',    
        boxShadow: "0px 0px 10px 0px #c100c4",
        borderRadius: '4px',
      }}
      style={{color: "white",}}
        onClick={fetchTokenData}
        disabled={!tokenAddress}
      >
        Search
      </Button>



      </HorizontalStack>
      {loading && <CircularProgress />}

      {tokenData && (
          <motion.div
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <Container style={{marginTop: "1rem", 
              backgroundColor: "black", padding: "10px", gap: "0.3rem",
              borderRadius: "0.5rem", display: 'flex', flexDirection: "column"
            }}>
            
                <Typography style={{
                  fontSize: '12px', color: 'white'
                }}>
                
                Name:
                <a style={{color: "white", marginLeft: '10px' }} href={`https://twitter.com/search?q=%23${tokenData.symbol}&src=typed_query&f=top`}>
                   {`${tokenData.name.substring(0, 12)}..`}</a> | Symbol: {tokenData.symbol || 'N/A'}
                </Typography>

                <Typography style={{marginRight: "10px",
                  fontSize: '12px', color: 'white'
                }}>
                  Price $USD:
                {tokenData.price_usd.substring(0,6) || 'N/A'} - Volume 24h: ${tokenData.volume_usd.h24.substring(0,6)|| 'N/A'}

                </Typography>
                <Typography style={{
                  fontSize: '12px', color: 'white'
                }}> 
                Market Cap: {tokenData.market_cap_usd|| 'N/A'}
                </Typography>
                </Container>
              {/* Aggiungi qui ulteriori dettagli come prezzo, link ai social, ecc. */}
              {tokenData.telegram_handle && (
                <ListItem>
                  <Link href={`https://t.me/${tokenData.telegram_handle}`} target="_blank">
                    <FontAwesomeIcon icon={faLink} /> Telegram
                  </Link>
                </ListItem>
              )}

          </motion.div>
        )}
      </Card>
    </motion.div>
)}

export default TokenSearch;