import { useTheme } from "@emotion/react";
import logobanner from "../assets/img/face-logo.png";
import traphome from "../assets/img/logo-banner.png";
import UsefulLinks from "../components/UsefulLinks";
import {
  IconButton,
  useMediaQuery,
  Drawer,
  Typography,
  Button,
  Container,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-icons/ai";
import "react-icons/ri";

import house from "../assets/img/logo-banner.png";
import { faCoins, faList, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { isLoggedIn, logoutUser } from "../helpers/authHelper";
import UserAvatar from "./UserAvatar";
import {faEthereum} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faMessage, faEarth } from "@fortawesome/free-solid-svg-icons";
import CryptoPrices from "./CryptoPrices";
import { getPosts } from '../api/posts';
import CreatePost from "./CreatePost";
import "../components/Home.css";
import FindUsers from "../components/FindUsers";
import ConnectedUsers from "../components/ConnectedUsers";

const Navbar = (createPost, props) => {
  const navigate = useNavigate();
  const user = isLoggedIn();
  const username = user && isLoggedIn().username;
  const [sidebarOpen, setSidebarOpen] = useState(false); // New state for sidebar
  const [width, setWindowWidth] = useState(0);
  const [postCount, setPostCount] = useState(0);
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await getPosts(); // Modify this call as per your API structure
        if (response && response.data) {
          setPostCount(response.count);
        }
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    const width = window.innerWidth;
    setWindowWidth(width);
  };

  const handleLogout = async (e) => {
    logoutUser();
    navigate("/login");
  };

    return (
    <Container  style={{ marginTop: isMobile ?  "0.2rem" : undefined,
      display: 'flex', flexDirection: 'column', marginBottom: '0.5rem',
    }}>

      <Container
        style={{
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: isMobile ? 'column' : undefined,
        }}
      >
          <div style={{alignItems: isMobile ? "center" : undefined, justifyContent: isMobile ? 'center' : undefined, display: "flex", width: isMobile ? '100%' :  "100%"}}>

          <img src={logobanner} style={{marginRight: "10px", width: "20px", objectFit: "contain"}}></img>

          <Typography style={{display: "flex", alignItems: "center",  fontSize: isMobile? "30px" : "14px", color: "white"}}>
                  TRAP HOU$E
                  </Typography>
                  <img src={logobanner} style={{marginLeft: "10px", width: "20px", objectFit: "contain"}}></img>
        </div>
          <UsefulLinks />         
      <Drawer
        anchor="right"
        open={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        {/* Content of the Sidebar MOBILE */}
        <Container
          style={{borderRadius: "1rem", 
          borderBottomLeftRadius: "0rem",
          height: "100%",
          borderBottomRightRadius: "0rem", 
          display: "flex", padding: "15px", 
          borderLeft: "1px solid #c100c4", boxShadow: "0px 0px 20px 1px #c100c4",
          flexDirection: "column", gap: "0.6rem"}}
          role="presentation"
          onClick={() => setSidebarOpen(false)}
          onKeyDown={() => setSidebarOpen(false)}
        >
<IconButton style={{color: 'white', display: "flex", gap: "1rem"}} component={Link} to={"/"}>
<img src={traphome} alt="traphome" style={{width: "30px", objectFit: "contain"}}></img>
            Menu
            <img src={traphome} alt="traphome" style={{width: "30px", objectFit: "contain"}}></img>
          </IconButton>

          <div style={{border: '1px solid white'}}></div>

          {user ? (
            <>


    <Container style={{display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center"}}>
            <IconButton component={Link}  to={"/users/" + username}>
                <UserAvatar width={25} height={25} username={user.username} />
                <Typography style={{marginLeft: "10px", fontSize: "14px", color: 'white'}}>
                  {user.username}
                </Typography>
              </IconButton>
    </Container>

      <Button style={{ color: '#c100c4',   backgroundColor: 'black', border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4"}}  onClick={handleLogout}>
        Logout
      <FontAwesomeIcon style={{marginLeft: "10px", color:" white"}} icon={faArrowRightFromBracket}></FontAwesomeIcon>

  </Button>
            </>
          ) : (
            <Container style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
              <Button variant="text" sx={{ minWidth: 80, color: '#c100c4',   backgroundColor: '#010101', border: '1px groove white',    
  boxShadow: "0px 0px 12px 0px #c100c4",  }} 
              onClick={() => navigate("/signup")}>
                Signup
              </Button>
              <Button variant="text" sx={{  minWidth: 80, color: '#c100c4',   backgroundColor: '#010101', border: '1px groove white',    
  boxShadow: "0px 0px 12px 0px #c100c4", }} 
              onClick={() => navigate("/login")}>
                Login
              </Button>
              </Container>
          )}
        
        <Container style={{display: "flex", alignItems: 'center', justifyContent: "center", flexDirection: "column"}}>
          
{createPost && <CreatePost />}


<Link component={Link} to={"/messenger"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>Messenger
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faMessage}></FontAwesomeIcon>
      </Button>
              </Link>
             
              <Link component={Link} to={"/tools/memo"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        width: isMobile ? 'auto' : '150px',
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>MEMO
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faPenToSquare}></FontAwesomeIcon>
      </Button>
              </Link>
              <Link component={Link} to={"/tools/erc20"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        width: isMobile ? 'auto' : '150px',
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>ERC20
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faEthereum}></FontAwesomeIcon>
      </Button>
              </Link>
              </Container>

        <UsefulLinks />

        </Container>
        
      </Drawer>

      {!isMobile && (
        <>
                      <Link to='/trap-wall'>
          <img src={house} alt="house"  style={{width: "30px", marginTop: '4px', objectFit: "contain"}}></img>
          </Link>
      {createPost && <CreatePost />}

      <Link component={Link} to={"/tools/memo"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        height: "25px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>MEMO
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faPenToSquare}></FontAwesomeIcon>
      </Button>
              </Link>
              <Link component={Link} to={"/tools/coin-tool"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        height: "25px",
        width: "120px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>Coin Tool
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faCoins}></FontAwesomeIcon>
      </Button>
              </Link>

              <Link component={Link} to={"/tools/erc20"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        height: "25px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>ERC20
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faEthereum}></FontAwesomeIcon>
      </Button>
              </Link>


<Link component={Link} to={"/messenger"}>
                <Button   sx={{
        backgroundColor:"#c100c4",
        fontSize: "12px",
        height: "25px",
        border: '1px groove white',    
        boxShadow: "0px 0px 5px 0px #c100c4",
        borderRadius: '4px',
        color: 'white', fontWeight: "bolder"
      }}>Chat
      <FontAwesomeIcon style={{marginLeft: "8px"}} icon={faMessage}></FontAwesomeIcon>
      </Button>
              </Link>

              <Link to='/trap-wall'>
          <img src={house} alt="house"  style={{width: "30px", marginTop: '4px', objectFit: "contain"}}></img>
          </Link>

          
          {user ? (
      <Container style={{display: 'flex', alignItems: 'center', justifyContent: "center"}}>
            {isLoggedIn ? (
            <Typography style={{textAlign: "center", marginLeft: "10px", fontSize: "14px", color: "white"}}>
              {username}
            </Typography>
               ) : (
                <Typography style={{textAlign: "center", marginLeft: "10px", fontSize: "14px", color: "white"}}>
                Welcome guest
              </Typography>
               )}

              <IconButton component={Link}  to={"/users/" + username}>
                <UserAvatar width={25} height={25} username={user.username} />
              </IconButton>

              <Button style={{ color: '#c100c4',   backgroundColor: 'black', border: '1px groove white',    
  boxShadow: "0px 0px 5px 0px #c100c4"}}  onClick={handleLogout}>

      <FontAwesomeIcon style={{color:" white"}} icon={faArrowRightFromBracket}></FontAwesomeIcon>

  </Button>
  </Container>
          ) : (
            <>
              <Button variant="text" sx={{ minWidth: 80, color: '#c100c4',   backgroundColor: '#010101', border: '1px groove white',    
  boxShadow: "0px 0px 12px 0px #c100c4",  }} 
              onClick={() => navigate("/signup")}>
                Signup
              </Button>
              <Button variant="text" sx={{  minWidth: 80, color: '#c100c4',   backgroundColor: '#010101', border: '1px groove white',    
  boxShadow: "0px 0px 12px 0px #c100c4", }} 
              onClick={() => navigate("/login")}>
                Login
              </Button>
            </>
          )}
</>
)}

      </Container>

      
      {isMobile  && (
        <Container style={{display: 'flex', gap: "0.6rem", marginBottom: "0.4rem", flexDirection: 'column'}}>
<Typography style={{ textAlign: "center", fontSize: "20px", color: "white" }}>
    {user ? (
        <Typography style={{ display: 'flex', flexDirection: "column", fontSize: "16px" }}>
            {username}, is that you?
        </Typography>
    ) : (
        <Typography style={{ fontSize: "16px" }}>
            Welcome
        </Typography>
    )}
</Typography>

         {!isMobile && (
        <ConnectedUsers / >
          )}
        <Button onClick={toggleSidebar} style={{ backgroundColor: "#c100c4", color: 'white' }}>
            Menu
          </Button>
        </Container>
      )}
      {/* {!isMobile && (
<CryptoPrices />
      )} */}
    </Container>
  );
};

export default Navbar;
