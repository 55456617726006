import {
    Avatar,
    Card,
    Container,
    Divider,
    IconButton,
    Stack,
    Typography,
    useMediaQuery
  } from "@mui/material";

import discord from "../assets/img/dslogo.png";
import xlogo from "../assets/img/xlogo.png";
import telegram from "../assets/img/telegram.png";

import React from "react";
import { GiPodiumWinner } from 'react-icons/gi';
import HorizontalStack from "./util/HorizontalStack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faLink } from "@fortawesome/free-solid-svg-icons";
  const UsefulLinks = () => {
    const mobileWidth = 600;
    const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
    return (
      <Container style={{justifyContent: "center", width: "100%", display: "flex", alignItems: "center", gap: "1rem"}}>
    <a style={{color: "white",}}  href="https://discord.gg/UFx6ah48t9" target="blank" rel="noreferrer noopener">
      <img src={discord} alt="discord" style={{width: "20px", objectFit: "contain"}}></img> 
    </a>
  
    <a style={{color: "white"}}  href="https://x.com/traphouse_gvng" target="blank" rel="noreferrer noopener">
    <img src={xlogo} alt="discord" style={{width: "20px", objectFit: "contain"}}></img> 
    </a>
   
   <a style={{color: "white", }}  href="https://t.me/+rO6yX0YhKlU1N2Y0" target="blank" rel="noreferrer noopener">
   <img src={telegram} alt="discord" style={{width: "20px", objectFit: "contain"}}></img> 
   </a>

</Container> 
    );
  };
  
  export default UsefulLinks;
  