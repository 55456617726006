import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import '../components/Home.css';
import { useMediaQuery } from '@mui/material';
import { isLoggedIn } from '../helpers/authHelper';
import { useNavigate } from 'react-router-dom';
const HomeComponent = () => {
  const [writtenText, setWrittenText] = useState('');
  const user = isLoggedIn()
  const textForUnauthenticated = "Run the trap!"; // Testo per gli utenti non autenticati
  const textForAuthenticated = `Welcome`; // Testo per gli utenti autenticati
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Reindirizza a /join se l'utente non è autenticato
    }
  }, [user, navigate]);

  useEffect(() => {
    const fullText = user ? textForAuthenticated : textForUnauthenticated;

    const intervalId = setInterval(() => {
      setWrittenText((currentText) => {
        if (currentText.length < fullText.length) {
          return fullText.slice(0, currentText.length + 1);
        }
        clearInterval(intervalId);
        return currentText;
      });
    }, 70);

    return () => clearInterval(intervalId);
  }, [user]); // Aggiungi user come dipendenza per riavviare l'effetto al cambio di stato

  const textAnimation = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1, ease: "easeOut" }
    }
  };

  return (
    <motion.div className="trap-house-container" variants={textAnimation}>
      <div className="scrolling-text">
        <span style={{fontSize: isMobile ? "22px" : '26px'}} className="scrolling-text">{writtenText}</span>
      </div>
    </motion.div>
  );
};

export default HomeComponent;
