import {
  Card,
  Container,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // If you want to use an icon
import {Button} from "@mui/material";
import React, { useState } from "react";
import { AiFillCheckCircle, AiFillDelete, AiFillEdit } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { deletePost, likePost, unlikePost, updatePost } from "../api/posts";
import { isLoggedIn } from "../helpers/authHelper";
import { useMediaQuery } from "@mui/material";
import facelogo from "../assets/img/face-logo.png";
import ContentDetails from "./ContentDetails";
import { MdLeaderboard } from "react-icons/md";
import { Modal} from "@mui/material";
import LikeBox from "./LikeBox";
import PostContentBox from "./PostContentBox";
import HorizontalStack from "./util/HorizontalStack";
import {} from "react-icons/ai";
import ContentUpdateEditor from "./ContentUpdateEditor";
import Markdown from "./Markdown";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinShareButton,
  TwitterIcon,
  WhatsappIcon,
  XIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinIcon
} from 'react-share';
import { Menu, MenuItem } from "@mui/material";
import "./postCard.css";
import { MdCancel } from "react-icons/md";
import { BiTrash } from "react-icons/bi";
import { MdMoreHoriz } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faMinus, faThumbsUp, faArrowDownLong, faShareAltSquare } from "@fortawesome/free-solid-svg-icons";

const PostCard = (props) => {
  const { preview, removePost } = props;
  let postData = props.post;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const user = isLoggedIn();
  const isAuthor = user && user.username === postData.poster.username;
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const theme = useTheme();
  const iconColor = theme.palette.primary.main;
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [editing, setEditing] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [post, setPost] = useState(postData);
  const [likeCount, setLikeCount] = useState(post.likeCount);
  const [copyText, setCopyText] = useState('Copy link');
  const [copyIcon, setCopyIcon] = useState(<ContentCopyIcon style={{marginLeft: "5px", fontSize: "12px"}} />);
  const [deleteStatus, setDeleteStatus] = useState({ confirm: false, deleted: false });

  const shareUrl = `https://traphousegvng.com/posts/${post._id}`;

 // State for menu
 const [menuAnchorEl, setMenuAnchorEl] = useState(null);
 const isMenuOpen = Boolean(menuAnchorEl);

 // Handle opening the menu
 const handleMenuOpen = (event) => {
   setMenuAnchorEl(event.currentTarget);
 };

 const handleMenuClose = () => {
  setMenuAnchorEl(null);

  // Resetta lo stato di deleteStatus quando il menu viene chiuso
  setDeleteStatus({ confirm: false, deleted: deleteStatus.deleted });
};
  
  let maxHeight = null;
  if (preview === "primary") {
    maxHeight = 250;
  }

  const handleDeletePost = async (e) => {
    if (e) e.stopPropagation();
    if (!deleteStatus.confirm) {
      // Primo clic, chiede conferma senza chiudere il menu
      setDeleteStatus({ ...deleteStatus, confirm: true });
      // Non chiude il menu qui
    } else {
      // Secondo clic, esegue l'azione di eliminazione
      setLoading(true);
      await deletePost(post._id, isLoggedIn());
      setLoading(false);
      setDeleteStatus({ confirm: false, deleted: true });
      
      // Visualizza il messaggio 'Eliminato' per 2 secondi, poi naviga via o rimuove il post
      setTimeout(() => {
        // Chiude il menu dopo la conferma
        handleMenuClose();
  
        if (preview) {
          removePost(post);
        } else {
          navigate("/trap-wall");
        }
      }, 2000);
    }
  };
  
  

  const handleEditPost = async (e) => {
    if (e) e.stopPropagation();
    setEditing(!editing);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const content = e.target.content.value;
    await updatePost(post._id, isLoggedIn(), { content });
    setPost({ ...post, content, edited: true });
    setEditing(false);
  };

  const handleLike = async (liked) => {
    if (liked) {
      setLikeCount(likeCount + 1);
      await likePost(post._id, user);
    } else {
      setLikeCount(likeCount - 1);
      await unlikePost(post._id, user);
    }
  };

 // Updated function to copy post link to clipboard
 const copyLinkToClipboard = () => {
  navigator.clipboard.writeText(shareUrl)
    .then(() => {
      // Change text and icon to indicate copied status
      setCopyText('Copied');
      setCopyIcon(<AiFillCheckCircle style={{marginLeft: "5px", fontSize: "12px", color: "green"}} />);

      // Revert back to original state after 2 seconds
      setTimeout(() => {
        setCopyText('Copy link');
        setCopyIcon(<ContentCopyIcon style={{marginLeft: "5px", fontSize: "12px"}} />);
      }, 2000);
    })
    .catch(err => {
      console.error("Failed to copy link: ", err);
    });
};

  return (
        <Card sx={{ padding: 0, border: "3px groove #c100c4 ",
            borderRadius: "1rem" , marginBottom: "1rem",
            color: 'white'}}>

          <Box sx={{
                    borderWidth: "1px", 
                    boxShadow: "0px 0px 10px 0px #c100c4", 
            fontSize: '12px',
             color: 'white',
            }} className={preview}>
        <HorizontalStack spacing={0} alignItems="initial">
       
          
          <PostContentBox  clickable={preview} post={post} editing={editing}>
            <HorizontalStack style={{background: 'black',
            height: "25px", padding: "15px", borderRadius: "0.4rem", color: 'white'
}} borderRadius='0.4rem' justifyContent="space-between">
              <ContentDetails
                username={post.poster.username}
                createdAt={post.createdAt}
                edited={post.edited}
                preview={preview === "secondary"}
              />
                <Box>
      {user && (isAuthor || user.isAdmin) && preview !== "secondary" && (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleMenuOpen}
            size="small"
            style={{color: 'white'}}
          >
            <MdMoreHoriz style={{fontSize: "20px"}} />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={menuAnchorEl}
            keepMounted
            open={isMenuOpen}
            onClose={handleMenuClose}
          >
            <MenuItem
              onClick={copyLinkToClipboard}
              style={{ backgroundColor: '#c100c4', fontSize: '12px', color: "white", border: '2px solid black', boxShadow: "0px 0px 3px 0px black"}}
            >
              {copyText} {copyIcon}
            </MenuItem>
            <MenuItem style={{backgroundColor: '#c100c4', fontSize: '10px', color: "white", border: '2px solid black', boxShadow: "0px 0px 3px 0px black"}} onClick={() => { handleEditPost(); handleMenuClose(); }}>
              Edit <AiFillEdit style={{marginLeft: '5px'}} />
            </MenuItem>
            <MenuItem style={{fontSize: '10px', color: "white", backgroundColor: '#c100c4', border: '2px solid black', boxShadow: "0px 0px 3px 0px black" }} onClick={() => { handleDeletePost()}}>
              {deleteStatus.confirm ? 'Confirm?' : (deleteStatus.deleted ? 'Deleting..' : 'Delete')} <AiFillDelete  style={{marginLeft: "5px"}} />
              {deleteStatus.deleted && <AiFillCheckCircle style={{marginLeft: "5px", fontSize: "12px", color: "green"}} />}
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
            </HorizontalStack>
            

            {preview !== "secondary" &&
              (editing ? (
                <ContentUpdateEditor
                  handleSubmit={handleSubmit}
                  originalContent={post.content}
                />
              ) : (
                <Box 
                  maxHeight={maxHeight}
                  overflow="hidden"
                  className="content"
                >
                </Box>
              ))}
<Card sx={{  "&:hover": { cursor: "pointer", color: 'black' }
}} style={{
  padding: '5px',
  border: "none",
  borderRadius: '0.4rem',
  backgroundColor: "transparent",
  boxShadow: "none",
  color: 'white',
}}
  onClick={() => navigate("/posts/" + post._id)}
>
        
    <Markdown content={post.content} />
</Card>

<HorizontalStack
  style={{
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isMobile ? 'column' : undefined,
    alignItems: "center",
    padding: "10px", gap: isMobile ? '0.5rem' : undefined,
    height: isMobile ? undefined : '50px',
    backgroundColor: "black",
    borderTop: "1px solid #c100c4" // Aggiungi il bordo superiore qui
  }}
>
<Typography style={{fontSize: "12px", color: 'white'}}>
    Category: <span style={{color: "#c100c4"}}>{post.category}</span>
  </Typography>

  <Container style={{display: 'flex', gap:" 0.3rem", alignItems: "center", justifyContent: "center"}}>


    {/* Bottoni di condivisione */}
    <TwitterShareButton url={shareUrl}>
      <XIcon style={{borderRadius: "1rem", border: "1px solid white", height: "18px", width: "18px"}}/>
    </TwitterShareButton>
    <TelegramShareButton url={shareUrl}>
      <TelegramIcon style={{borderRadius: "1rem", border: "1px solid white", height: "18px", width: "18px"}}></TelegramIcon>
    </TelegramShareButton>
    <WhatsappShareButton url={shareUrl}>
      <WhatsappIcon style={{borderRadius: "1rem", border: "1px solid white", height: "18px", width: "18px"}}></WhatsappIcon>
    </WhatsappShareButton>
    <LinkedinShareButton url={shareUrl}>
      <LinkedinIcon style={{borderRadius: "1rem", border: "1px solid white", height: "18px", width: "18px"}}></LinkedinIcon>
    </LinkedinShareButton>
    <FacebookShareButton url={shareUrl}>
      <FacebookIcon style={{borderRadius: "1rem", border: "1px solid white", height: "18px", width: "18px"}} />
    </FacebookShareButton>
  </Container>

<Typography
variant="subtitle2"
color="text.secondary"
sx={{ fontWeight: "bolder", color: "#c100c4", textAlign: 'right', fontSize: '12px'}}
>

<FontAwesomeIcon style={{ marginLeft: "3px", marginRight: '5px'}} icon={faThumbsUp}></FontAwesomeIcon>
<span style={{color: "white"}}>{post.likeCount}</span> like -
<FontAwesomeIcon  style={{ marginRight: '5px'}}icon={faComment}></FontAwesomeIcon>
{post.commentCount === 0 ? (
"0 comments" 
) : post.commentCount === 1 ? (
`1 comment`
) : (
<span style={{color: "white"}}>{post.likeCount}</span> 
)}

</Typography>


</HorizontalStack>
            
          </PostContentBox>
        </HorizontalStack>
      </Box>
    </Card>
  );
};

export default PostCard;