import { useEffect } from 'react';
import { Container, Typography, Button, useMediaQuery } from '@mui/material';
import HomeComponent from '../components/HomeComponent';
import house from "../assets/img/logo-banner.png";
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../helpers/authHelper';
import { useNavigate } from 'react-router-dom';
import JoinComponent from '../components/JoinComponent';
function Homepage() {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const user = isLoggedIn();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/'); // Reindirizza a /join se l'utente non è autenticato
    }
  }, [user, navigate]);

  return (
    <Container 
      style={{ display: 'flex', padding: "5px", flexDirection: 'column', width: "100%",
      alignItems: 'center', justifyContent: 'center', color: '#c100c4' }}
    >

      <HomeComponent />
      <Container style={{ width: '300px', padding: "10px", borderRadius: "0.3rem",
        display: 'flex', flexDirection: 'column', gap: "1rem",
        alignItems: "center", justifyContent: "center" }}>
        <img src={house} alt='facelogo' style={{ width: '80px' }}></img>
        <Link to={user ? '/trap-wall' : '/login'}>
          <Button style={{ width: "150px",
            borderBottom: '3px solid #c100c4',
            boxShadow: '0px 0px 3px 0px #c100c4',
            borderTop: 'none',
            borderLeft: "none",
            borderRight: 'none',
            borderRadius: "0.3rem" ,
            color: 'white', backgroundColor: "black" }}
            variant="contained" color="primary">
            {user ? 'ENTER' : 'ACCESS'}
          </Button>
        </Link>
        {!user && (
          
        <JoinComponent />
        )}
      </Container>
    </Container>
  );
}

export default Homepage;
