import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import { Card, Typography, IconButton, Container } from '@mui/material';
import { motion } from 'framer-motion';
import etherscan from "../assets/img/etherscan.png";
import dex from "../assets/img/dex.png";
import Loader from '../components/Loading';
import { isLoggedIn } from '../helpers/authHelper';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';

const UNISWAP_FACTORY_ADDRESS = "0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f";

const UNISWAP_FACTORY_ABI = [
  "function getPair(address tokenA, address tokenB) external view returns (address pair)",
  "event PairCreated(address indexed token0, address indexed token1, address pair, uint)"
];

const ERC20_ABI = [
  "function name() external view returns (string)",
  "function symbol() external view returns (string)",
  "function totalSupply() external view returns (uint256)"
];

const expandedContentStyle = {
  maxHeight: '0',
  overflow: 'hidden',
  transition: 'max-height 0.3s ease-in-out',
};

const expandedContentStyleActive = {
  maxHeight: '500px',
};

const gridContainerStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '20px',
  padding: '30px',
  borderRadius: '1rem',
};

const cardStyle = {
  border: '1px solid #c100c4',
  color: 'white',
  padding: '10px',
  backgroundColor: 'black',
  borderRadius: '0.4rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center'
};

const Erc20 = () => {
  const mobileWidth = 600;
  const isMobile = useMediaQuery(`(max-width: ${mobileWidth}px)`);
  const [pairs, setPairs] = useState([]);
  const user = isLoggedIn();
  const [expandedCard, setExpandedCard] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login'); // Reindirizza a /join se l'utente non è autenticato
    }
  }, [user, navigate]);

  const handleCardClick = (index) => {
    setExpandedCard(prevIndex => (prevIndex !== index ? index : null));
  };

  useEffect(() => {
    const provider = new ethers.providers.WebSocketProvider("wss://mainnet.infura.io/ws/v3/fed679d327de4db58597d792b3a99fcf");
    const uniswapFactory = new Contract(UNISWAP_FACTORY_ADDRESS, UNISWAP_FACTORY_ABI, provider);

    const fetchTokenDetails = async (tokenAddress) => {
      const token = new Contract(tokenAddress, ERC20_ABI, provider);
      const [name, symbol, totalSupply] = await Promise.all([
          token.name(),
          token.symbol(),
          token.totalSupply()
      ]);
      const address = token.address;
      return { name, symbol, address, totalSupply: totalSupply.toString() };
    };

    const fetchLastPairs = async () => {
      const pastEvents = await uniswapFactory.queryFilter("PairCreated", -5); // get last 5 PairCreated events
      const lastPairs = await Promise.all(pastEvents.map(async event => {
        const [details0, details1] = await Promise.all([fetchTokenDetails(event.args?.token0), fetchTokenDetails(event.args?.token1)]);
        return {
          token0: details0,
          token1: details1,
          pairAddress: event.args?.pair
        };
      }));
      setPairs(lastPairs.reverse());
    };

    fetchLastPairs();

    uniswapFactory.on("PairCreated", async (token0, token1, pairAddress) => {
      const [details0, details1] = await Promise.all([fetchTokenDetails(token0), fetchTokenDetails(token1)]);
      const newPair = {
        token0: details0,
        token1: details1,
        pairAddress
      };
      setPairs(prevPairs => [newPair, ...prevPairs]);
    });

    return () => {
      uniswapFactory.removeAllListeners("PairCreated");
    };
  }, []);


  return (
    <>
        <Container 
      style={{gap: "0.5rem", display: 'flex', padding: "5px", flexDirection: 'column',
       alignItems: 'center', justifyContent: 'center', color: '#c100c4' }}
    >

      <Typography variant="h4" style={{display: 'flex', justifyContent: "center", alignItems: "center", fontSize: '20px', color: 'white' }}>
        UNISWAP POOLS
        LIVE LISTENER
        <img src='https://cryptologos.cc/logos/uniswap-uni-logo.png' alt="logo" 
      style={{ transform: "scaleX(-1)", borderRadius: "50%", 
      width:  isMobile? "30px" : "30px", objectFit: 'contain', marginLeft: "10px"}} />
      </Typography>
      <Typography style={{fontSize: "16px"}}>- ERC20 Tokens <br/>
      - Mainnet <br />
      - Pair <a style={{textDecoration: "underline"}} href="https://etherscan.io/token/0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"target="blank" rel="noreferrer noopener">$WETH</a>
      </Typography>
    </Container>
    <Loader /> {/* This will render the rotating loader */}

    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={gridContainerStyle}
    >
      {pairs.map((pair, idx) => (
        <motion.div 
          key={idx}
          whileHover={{ scale: 1.05, boxShadow: '0px 0px 8px black' }}
          transition={{ duration: 0.3 }}
        >
          <Card style={cardStyle} onClick={() => handleCardClick(idx)}>
          <div style={{paddingBottom: '8px', marginBottom: '8px' }}>
              <Typography style={{color: "orange", fontWeight: "bold", fontSize: '12px'}}>{pair.token0.name}
              <br />  <span style={{color:" white"}}>SYMBOL:</span>{pair.token0.symbol}</Typography>
              <Typography style={{fontWeight: "bold", fontSize: '12px', borderBottom: '1px solid #c100c4', paddingBottom: '8px', marginBottom: '8px' }}>
                Contract:  <span style={{color: "#c100c4", fontSize: "10px", fontWeight: "bolder"}}>{pair.token0.address}</span>
              </Typography>
              {expandedCard !== idx && (  // Controlla se la card non è espansa
          <a style={{fontSize: "12px",  fontFamily: "Proto", borderRadius: "0.4rem", backgroundColor: 'white', padding: "5px", cursor: 'pointer'}}>Expand info</a>
        )}
              <div style={expandedCard === idx ? {...expandedContentStyle, ...expandedContentStyleActive} : expandedContentStyle}>
              <Typography style={{fontSize: "10px"}} >Total Supply: {pair.token0.totalSupply}</Typography>
              <Typography>
              <Typography style={{fontSize: "10px", paddingBottom: '8px', marginBottom: '8px' }}>
                Pair Address: {pair.pairAddress}
              </Typography>              </Typography>
            </div>
              </div>

            <div style={{
              backgroundColor: '#c100c4',
              padding: '5px',
              fontSize: "10px",
              width: "100%",
              display: 'flex',
              justifyContent: 'space-around'
            }}>

              <IconButton component="a" href={`https://app.uniswap.org/tokens/ethereum/${pair.token0.address}`} target="_blank" rel="noopener noreferrer">
                <img src={dex} alt='dex' 
                style={{width: "20px", objectFit: "contain"}}></img>
              </IconButton>
              <IconButton component="a" href={`https://www.dextools.io/app/uniswap/pair-explorer/${pair.token0.address}`} target="_blank" rel="noopener noreferrer">
              <img src='https://cryptologos.cc/logos/uniswap-uni-logo.png' alt="logo" style={{ width: "30px", objectFit: 'contain'}} />
              </IconButton>
              <IconButton component="a" href={`https://etherscan.io/token/${pair.token0.address}`} target="_blank" rel="noopener noreferrer">
              <img src={etherscan} alt="logo" style={{width: "20px", objectFit: 'contain'}} />
              </IconButton>
            </div>
          </Card>
        </motion.div>
      ))}
    </motion.div>
    </>
  );
}


export default Erc20;