import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "'Roboto Mono', monospace",
    fontWeightBold: 'true',
  },
  components: {
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
   color: 'white', backgroundColor: "black",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: '12px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontWeight: "bold",
          color: '#C100C4'
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'black',
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "white"
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#C100C4',
          border: "none",
          fontWeight: "normal"
        },
      }
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "100%"
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '8px',
          fontFamily: "'Roboto Mono', monospace",
        },
      },
    },
  },
});

export default theme;
